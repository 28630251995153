<template>
  <div>
    <PaymentTitle type="Add" />

    <VLoader v-if="_fetchingStatus === requestStatus.PENDING" />
    <HandlePaymentDetails v-if="_fetchingStatus === requestStatus.SUCCESS" />
  </div>
</template>

<script>
import HandlePaymentDetails from '@/js/components/pages/payments/HandlePaymentDetails';
import PaymentTitle from '@/js/components/pages/payments/HandlePaymentDetails/PaymentTitle';
import { requestStatus } from '@/js/utils/constants';

export default {
  name: "CreatePaymentMethod",
  components: {
    HandlePaymentDetails,
    PaymentTitle
  },
  data: () => ({
    requestStatus,
  }),
  computed: {
    _fetchingStatus() {
      return this.$store.state.paymentMethods.fetchingStatus;
    },
    _paymentMethodsList() {
      return this.$store.state.paymentMethods.list;
    },
  },
  mounted() {
    if (!this._paymentMethodsList.length) this.getPaymentMethods();
  },
  methods: {
    async getPaymentMethods() {
      await this.$store.dispatch('paymentMethods/getPaymentMethods');
    },
  }
};
</script>

<style lang="scss" scoped>

</style>